<template>
    <div class="app" style="width: 100%; height: 100%">
        <el-container style="height: calc(100vh - 1px)">
            <el-aside style="background-color: black;height: 100vh;">
                <div class="app-side-logo" style="text-align: center">
                    <img v-if="isCollapse" style="width: 45px; height: 45px; border-radius: 50%; margin-top: 28px"
                        src="@/assets/logo.png" />
                    <span v-else style="font-size: 20px; font-weight: 600">智装荟后台管理系统</span>
                </div>
                <el-menu class="el-menu-vertical-demo hhhh" :collapse="isCollapse" background-color="black"
                    :router="true" text-color="#ffffff" active-text-color="#409EFF" :default-active="activeIndex"
                    :unique-opened="true">
                    <!-- 侧边导航组件 -->
                    <MenuTree :data="routes"></MenuTree>
                </el-menu>
            </el-aside>
            <el-container>
                <el-header>
                    <el-row>
                        <el-col :span="12" @click="isCollapse = !isCollapse"
                            style="text-align: left; color: #ffffff; line-height: 60px; font-size: 22px">
                            <i v-if="isCollapse" class="el-icon-s-unfold"></i>
                            <i v-else class="el-icon-s-fold"></i>
                        </el-col>
                        <el-col :span="12" style="text-align: right; color: #ffffff; font-size: 14px; cursor: pointer">
                            <span @click="changeMinePass" style="display: inline-block; padding: 0 15px">个人信息</span>
                            <span @click="logout" style="display: inline-block; padding: 0 15px">退出</span>
                        </el-col>
                    </el-row>
                </el-header>
                <el-main style="padding: 0">
                    <div class="app-wrap">
                        <!-- 此处放置el-tabs代码 -->
                        <el-affix :offset="60">
                            <div class="mainTab" style="width: 100%">
                                <el-tabs v-model="activeIndex" type="card" closable v-if="openTab.length"
                                    @tab-click="tabClick" @tab-remove="tabRemove">
                                    <el-tab-pane :key="item.name" v-for="item in openTab" :label="item.name"
                                        :name="item.path">
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                        </el-affix>
                        <div class="content-wrap loadingDiv" style="
                                padding: 0 25px;
                                background-color: rgb(249, 249, 249);
                                min-height: calc(100vh - 130px);
                            ">
                            <router-view />
                        </div>
                    </div>
                </el-main>
                <el-footer style="text-align: center">Copyright 2017-{{ getCurrentYear() }} www.zhipeihui.cn |
                    智装荟科技有限公司 |
                    沪ICP备17037444号-1</el-footer>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import MenuTree from '@/components/MenuTree';
import { loginOut } from '@/common/js/login.js';
import { getAllDynamicRouterList } from '@/common/api/permissions/index.js';
export default {
    name: 'Container',
    components: {
        MenuTree: MenuTree
    },
    data() {
        return {
            openTab: [], //所有打开的路由
            activeIndex: '', //激活状态
            MainColor: '#FFFFFF',
            username: 'Admin',
            isCollapse: false,
            keepAlive: false,
            headImg: '',
            nickname: '',
            mobile: '',
            // routesMenu: [],
            routes: []
        };
    },
    watch: {
        $route(to) {
            for (let i = 0; i < this.openTab.length; i++) {
                if (this.openTab[i].name == to.name) {
                    this.set_active_index(to.path);
                    this.openTab[i].keepAlive = false;
                    return;
                }
            }
            let data = { path: to.path, name: to.name };
            // console.log(data);
            this.set_active_index(to.path);
            this.add_tabs(data);
        }
    },
    created() {
        // this.routes = this.$router.options.routes;
        this.activeIndex = this.$route.path;
        this.openTab.push({ path: this.$route.path, name: this.$route.name });
        // if (this.$route.path != '/user') {
        //     this.openTab.push({ name: this.$route.name, path: this.$route.path });
        // }
        this.getMenu();
    },
    methods: {
        getMenu() {
            getAllDynamicRouterList().then(res => {
                this.routes = res.Menus;
            });
        },
        // 获取当年年份
        getCurrentYear() {
            let date = new Date();
            return date.getFullYear();
        },
        changeMinePass() {
            // if (this.mobile) {
            // window.sessionStorage.setItem('mobile', this.mobile);
            this.$router.push('/personalSetting');
            // }
        },
        tabClick() {
            this.$router.push({ path: this.activeIndex });
        },
        //移除tab标签
        tabRemove(targetName) {
            //首页不删
            if (targetName == '/' || targetName == '/dashboard') {
                return;
            }
            this.delete_tabs(targetName);
            if (this.activeIndex === targetName) {
                // 设置当前激活的路由
                if (this.openTab && this.openTab.length >= 1) {
                    this.set_active_index(this.openTab[this.openTab.length - 1].path);
                    this.$router.push({ path: this.activeIndex });
                }
            }
        },
        add_tabs(data) {
            data.keepAlive = this.$route.meta.keepAlive;
            this.openTab.push(data);
        },
        // 删除tabs
        delete_tabs(route) {
            let index = 0;
            for (let option of this.openTab) {
                if (option.path === route) {
                    break;
                }
                index++;
            }
            // if (route == '/memberUpload') {
            //     this.$store.mutations.SetKeep(this.$store.state, 'memberUpload');
            // }
            this.openTab.splice(index, 1);
        },
        // 设置当前激活的tab
        set_active_index(index) {
            this.activeIndex = index;
        },
        toggleSideBar() {
            this.isCollapse = !this.isCollapse;
        },
        logout() {
            this.$confirm('确认退出登录?', '提示', {})
                .then(() => {
                    loginOut()
                        .then(() => {
                            this.$store.dispatch('user/setToken', null);
                            sessionStorage.removeItem('token');
                            this.$store.commit('permissions/SET_MENU_LIST', null);
                            this.$router.push('/login');
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => { });
        },
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
        }
    }
};
</script>

<style>
.el-header {
    text-align: right;
    line-height: 60px;
    border-bottom: 1px solid #dcdfe6;
    background-color: #000000;
    height: 60px !important;
    /* z-index: 99999; */
}

.el-footer {
    border-top: 1px solid #dcdfe6;
    line-height: 25px;
    font-size: 12px;
    height: 25px !important;
    background-color: #ffffff;
    color: #909693;
}

.mainTab .el-tabs__nav-scroll {
    background-color: rgb(239, 238, 240);
}

.mainTab .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border-top: 0;
}

.mainTab .el-tabs--card>.el-tabs__header {
    margin-bottom: 0;
}

.el-aside {
    width: var(--el-menu-background-color) !important;
    line-height: 85px;
    /*border-right: solid 1px var(--el-menu-border-color);*/
    background-color: black;
    color: #ffffff;
}

.mainTab .el-tabs--card>.el-tabs__header .el-tabs__item.is-closable {
    padding-right: 18px !important;
    padding-left: 18px !important;
}

.mainTab .el-tabs__nav .el-tabs__item:first-child .el-icon-close:first-child {
    display: none;
}

.mainTab .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    border-bottom: 0px;
    background-color: #ffffff;
}

.el-aside .el-menu {
    border-right: 0px;
    background-color: black;
}

body>.el-container {
    margin-bottom: 40px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.el-dropdown-link {
    cursor: pointer;
    line-height: 60px;
}

.hhhh .el-icon-arrow-down {
    font-size: 12px;
    line-height: 15px;
}

.el-menu-item:hover {
    background-color: #33a2ef !important;
    color: #ffffff !important;
}

.el-menu-item i:hover {
    background-color: #33a2ef !important;
    color: #ffffff !important;
}

.el-submenu__title:hover {
    background-color: #33a2ef !important;
    color: #ffffff !important;
}

.app-wrap .el-affix--fixed {
    z-index: 2000 !important;
}
</style>
