import $http from '../../utils/http.js';

/**
 * 登录
 * @param data
 * @returns {Promise<unknown>}
 */
export function login(data){
    return $http.post("/login",data);
}

/**
 * 退出登录
 * @param data
 * @returns {Promise<unknown>}
 */
 export function loginOut(data){
    return $http.post("/Index/loginout",data);
}

/**
 * 发送验证码
 * @param data
 * @returns {Promise<unknown>}
 */
export function getYzm(data) {
    return $http.post("/Sms/send",data);
}

/**
 * 校验验证码
 * @param data
 * @returns {Promise<unknown>}
 */
export function validateYzm(data) {
    return $http.post("/Login/ck_sms",data);
}

/**
 * 更改密码
 * @param data
 * @returns {Promise<unknown>}
 */
export function changePassWord(data) {
    return $http.post("/Login/newpassword",data);
}